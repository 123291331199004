var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"width":"10% !important"},attrs:{"fluid":"","src":_vm.imgLogo,"alt":"Login V2"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg p-lg-2",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Register ")]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"fullName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"First and last name","label-for":"lastName"}},[_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.user.fullName),callback:function ($$v) {_vm.$set(_vm.user, "fullName", $$v)},expression:"user.fullName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"pays","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country","label-for":"pays"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.getList,"input-id":"pays","label":"libelle","state":errors.length > 0 ? false:null,"reduce":function (pays) { return pays['@id']; },"clearable":false},model:{value:(_vm.user.pays),callback:function ($$v) {_vm.$set(_vm.user, "pays", $$v)},expression:"user.pays"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"paysResidence","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country of residence","label-for":"paysResidence"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.getCountries,"input-id":"pays","label":"libelle","state":errors.length > 0 ? false:null,"reduce":function (pays) { return pays.id; },"clearable":false},model:{value:(_vm.user.paysResidence),callback:function ($$v) {_vm.$set(_vm.user, "paysResidence", $$v)},expression:"user.paysResidence"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"domaine","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Domain","label-for":"domaine"}},[_c('b-form-select',{attrs:{"id":"domainSelect","options":_vm.domains,"state":errors.length > 0 ? false:null},model:{value:(_vm.user.domaine),callback:function ($$v) {_vm.$set(_vm.user, "domaine", $$v)},expression:"user.domaine"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Phone number","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"adresse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Adress","label-for":"adresse"}},[_c('b-form-textarea',{attrs:{"id":"adresse","state":errors.length > 0 ? false:null,"rows":"3","trim":""},model:{value:(_vm.user.adresse),callback:function ($$v) {_vm.$set(_vm.user, "adresse", $$v)},expression:"user.adresse"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Password","label-for":"reset-password-new"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.password1FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-new","placeholder":"············"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"reset-password-confirm","label":"Repeat password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.password2FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-confirm","placeholder":"············"},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.loading}},[(_vm.loading === false)?_c('span',[_vm._v(" Sign up ")]):_c('span',[_c('b-spinner',{attrs:{"small":""}})],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.hide}},[_vm._v(" Empty ")])],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account ? ")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v("Login")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }