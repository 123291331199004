<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          fluid
          style="width: 10% !important;"
          :src="imgLogo"
          alt="Login V2"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg p-lg-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Register
          </b-card-title>

          <!-- form -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- FirstName -->
              <validation-provider
                #default="{ errors }"
                name="fullName"
                rules="required"
              >
                <b-form-group
                  label="First and last name"
                  label-for="lastName"
                >
                  <b-form-input
                    id="lastName"
                    v-model="user.fullName"
                    :state="errors.length > 0 ? false:null"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="pays"
                rules="required"
              >
                <b-form-group
                  label="Country"
                  label-for="pays"
                >
                  <v-select
                    v-model="user.pays"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="getList"
                    input-id="pays"
                    label="libelle"
                    :state="errors.length > 0 ? false:null"
                    :reduce="pays => pays['@id']"
                    :clearable="false"
                  />

                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="paysResidence"
                rules="required"
              >
                <b-form-group
                  label="Country of residence"
                  label-for="paysResidence"
                >
                  <v-select
                    v-model="user.paysResidence"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="getCountries"
                    input-id="pays"
                    label="libelle"
                    :state="errors.length > 0 ? false:null"
                    :reduce="pays => pays.id"
                    :clearable="false"
                  />

                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="domaine"
                rules="required"
              >
                <b-form-group
                  label="Domain"
                  label-for="domaine"
                >
                  <b-form-select
                    id="domainSelect"
                    v-model="user.domaine"
                    :options="domains"
                    :state="errors.length > 0 ? false:null"
                  />

                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- email -->
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="user.email"
                    :state="errors.length > 0 ? false:null"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- phone -->
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-group
                  label="Phone number"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="user.phone"
                    :state="errors.length > 0 ? false:null"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Adresse -->
              <validation-provider
                #default="{ errors }"
                name="adresse"
                rules="required"
              >
                <b-form-group
                  label="Adress"
                  label-for="adresse"
                >
                  <b-form-textarea
                    id="adresse"
                    v-model="user.adresse"
                    :state="errors.length > 0 ? false:null"
                    rows="3"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- password -->
              <b-form-group
                label="Password"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="user.password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Repeat password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="user.confirmPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="loading"
                >
                  <span v-if="loading === false"> Sign up </span>
                  <span v-else>
                    <b-spinner small />
                  </span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Empty
                </b-button>
              </div>

            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account ? </span>
            <b-link :to="{name:'login'}">
              <span>Login</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormInput, BFormSelect, BFormTextarea, BInputGroupAppend, BInputGroup, BFormGroup, BForm, BButton, BSpinner, BFormInvalidFeedback, BCardTitle, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BCardTitle,
    BImg,
    BLink,
    BForm,
    BButton,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    vSelect,
    BFormInvalidFeedback,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      username: '',
      password: '',
      status: '',
      password1FieldType: 'password',
      password2FieldType: 'password',
      loading: false,
      firstName: '',
      lastName: '',
      user: {
        fullName: '',
        adresse: '',
        email: '',
        phone: '',
        domaine: '',
        pays: '',
        paysResidence: '',
        password: '',
        confirmPassword: '',
      },
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation rules
      required,
      email,
      domains: [{ value: 'engineering & technology', text: 'Engineering and Technology' }, { value: 'Economics & Econometrics', text: 'Economy and Econometrics' }, { value: 'Medical and Health Sciences', text: 'Medical and Health Science' }, { value: 'Social Sciences', text: 'Social Science' }, { value: 'Natural Sciences', text: 'Natural Science' }],
    }
  },
  computed: {
    getList() {
      return this.$store.getters['pays/list']
    },
    getCountries() {
      return this.$store.getters['pays/countries']
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties, global-require
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    imgLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/logo/logo.png')
    },
  },
  async mounted() {
    await this.$store.dispatch('pays/listAll', { params: null })
    this.$store.dispatch('pays/list', { params: null })
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.firstName = ''
      this.lastName = ''
      this.user = {
        fullName: '',
        email: '',
        adresse: '',
        phone: '',
        domaine: '',
        pays: '',
        paysResidence: '',
        password: '',
        confirmPassword: '',
      }
    },
    hide() {
      this.firstName = ''
      this.lastName = ''
      this.user = {
        fullName: '',
        email: '',
        adresse: '',
        phone: '',
        domaine: '',
        pays: '',
        paysResidence: '',
        password: '',
        confirmPassword: '',
      }
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          this.loading = true
          await this.$store.dispatch('peoples/user', {
            page: 1,
            item: {
              email: this.user.email,
              password: this.user.password,
              roles: ['ROLE_USER'],
              isActive: false,
            },
            params: null,
          }).then(async () => {
            this.user.user = this.$store.getters['peoples/user']['@id']
            await this.$store.dispatch('peoples/add', { page: 1, item: this.user, params: null })
              .then(() => {
                this.$router.push('/login')
              }).catch(error => {
                this.loading = false
                if (error.response) {
                  this.$toast({
                    component: ToastificationContent,
                    duration: 8000,
                    props: {
                      title: `${error.response.data['hydra:description']}`,
                      icon: 'ErrorIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
          }).catch(error => {
            this.loading = false
            if (error.response) {
              this.$toast({
                component: ToastificationContent,
                duration: 8000,
                props: {
                  title: `${error.response.data['hydra:description']}`,
                  icon: 'ErrorIcon',
                  variant: 'danger',
                },
              })
            }
          })
          this.loading = false
        }
      })
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
